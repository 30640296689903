import { SyntheticEvent, useCallback, useMemo, useState } from 'react';
import * as XLSX from 'xlsx';
import { capitalize, debounce } from 'lodash';
import { endpoints } from 'variables/endpoint-urls';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setSnacksQueue } from 'redux/reducers/snacks';
import { Option } from './useIcpFilter';
import { CsvUploadModalFieldInfo } from 'components/ProspectList/ICPBuilder/CsvUploadModal';

export type LegacyAutocompleteResponse = {
  uuid: string;
  link: string;
  name: string;
  image: string;
  type: 'org' | 'profile';
};

export function defaultFilterFetchedOptions(
  data: LegacyAutocompleteResponse[]
) {
  return data
    .filter(
      (item: LegacyAutocompleteResponse) =>
        item.type === 'profile' || item.type === 'org'
    )
    .map((item: LegacyAutocompleteResponse) => ({
      label: item.name,
      value: item.uuid,
    }));
}

export interface UseAutoCompleteWithUploadProps {
  // Existing option values to display within the autocomplete.
  options: Option[];
  onValueChanged: (value: Option[]) => void;
  value: Option[];
  label: string;
  unique?: boolean; // limits the number of tags to 1 if true
  fetch?: boolean;
  disabledCustomValues?: boolean;
  autoCompleteRef: React.RefObject<HTMLDivElement>;
  // API function for fetching results. Note the default above is an old endpoint for backward compatibility.
  endpointFunction?: (inputValue: string) => string;
  // Processes the fetched payload. Necessary when specifying endpointFunction.
  filterFetchedOptions?: (data: any) => Option[];
}

/**
 * API fetching, state for autocomplete + CSV upload.
 */
export function useAutoCompleteWithUpload({
  options,
  onValueChanged,
  value,
  label,
  unique,
  fetch,
  disabledCustomValues,
  autoCompleteRef,
  endpointFunction = endpoints.autocomplete.get.prospects,
  filterFetchedOptions = defaultFilterFetchedOptions,
}: UseAutoCompleteWithUploadProps) {
  const formatedLabel = useMemo(() => label.replace('|', ' '), [label]);
  const [inputValue, setInputValue] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState<
    { fieldConfig: CsvUploadModalFieldInfo; value: string }[]
  >([]);
  const [file, setFile] = useState<File | null>(null);
  const [loadingFetch, setLoadingFetch] = useState(false);
  const dispatch = useDispatch();
  const [fetchedOptions, setFetchedOptions] = useState<
    {
      value: string;
      label: string;
    }[]
  >([]);
  const [csvData, setCsvData] = useState<{
    rows: string[][];
    columns: string[];
    selectedColumns: string[];
    csvError: string;
  }>({
    rows: [],
    columns: [],
    selectedColumns: [],
    csvError: '',
  });
  const [showConfirmUpload, setShowConfirmUpload] = useState(false);
  const mainLabel = (
    label.split('|').length > 1 ? label.split('|')[1] : label
  ).toLowerCase();

  /**
   * Postprocess the CSV data.
   */
  const handleReadData = useCallback((content: string) => {
    const lines = content.replace(/"/g, '').split('\n');
    const headers = lines[0].split(',');
    setCsvData((prev) => ({
      ...prev,
      columns: headers,
      rows: lines.slice(1).map((line) => line.split(',')),
      csvError: '',
    }));
  }, []);

  /**
   * Make the API call to fetch the autocomplete options.
   */
  const fetchAutocomplete = useCallback(
    async (inputValue: string) => {
      try {
        const { data } = await axios.get(endpointFunction(inputValue), {});

        setFetchedOptions(filterFetchedOptions(data));
      } catch (error) {
        dispatch(
          setSnacksQueue({
            message: 'Error fetching autocomplete options',
            timeout: 5000,
          })
        );
      } finally {
        setLoadingFetch(false);
      }
    },
    [dispatch, endpointFunction, filterFetchedOptions]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchAutocompleteDebounced = useCallback(
    debounce((value: string) => {
      setLoadingFetch(true);
      if (value) {
        fetchAutocomplete(value);
      } else {
        setFetchedOptions([]);
        setLoadingFetch(false);
      }
    }, 300),
    [fetchAutocomplete]
  );

  function readXlsAsCsv(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target?.result;
        const workbook = XLSX.read(data, { type: 'binary' });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        const csv = XLSX.utils.sheet_to_csv(worksheet);
        resolve(csv);
      };
      reader.onerror = reject;
      reader.readAsBinaryString(file);
    });
  }

  /**
   * Save uploaded file in state, pass it to the right parser.
   */
  const handleFile = useCallback(
    async (file: File) => {
      setFile(file);
      const extension = file.name.split('.').pop();
      if (extension === 'csv') {
        const reader = new FileReader();
        reader.onload = (e) => {
          const content = e.target?.result;
          if (typeof content === 'string') {
            handleReadData(content);
          }
        };
        reader.readAsText(file);
      } else if (extension === 'xls' || extension === 'xlsx') {
        const csvData = await readXlsAsCsv(file);
        handleReadData(csvData);
      }
      setOpenModal(true);
    },
    [handleReadData]
  );

  /**
   * Called when submitting the file upload.
   * Grabs the selected column from the CSV data and transforms it into Option[].
   * Then, merges it with the existing value and triggers onValueChanged and toggles modals.
   * Call this function directly.
   */
  const handleSubmit = useCallback(() => {
    if (selectedColumns.length && file) {
      // Map csvData to an array of rows...
      const data: Record<string, string>[] = csvData.rows.map((row) => {
        // For each row, return  a 2-element array with name & value
        return selectedColumns
          .map((column) => [
            // Column name
            column.fieldConfig.fieldName,
            // Value at column
            row[csvData.columns.indexOf(column.value)],
          ])
          .reduce((acc, [key, value]) => {
            // Merge the key/value pairs into an object
            return {
              ...acc,
              [key]: value,
            };
          }, {});
      });

      function getLabelFieldName(
        cols: { fieldConfig: CsvUploadModalFieldInfo; value: string }[]
      ): CsvUploadModalFieldInfo['fieldName'] {
        const fieldNames = cols.map((col) => col.fieldConfig.fieldName);
        if (fieldNames.length > 1 && fieldNames.includes('uuid')) {
          // if there's a uuid column, use the other field as the label field.
          return fieldNames.filter((name) => name !== 'uuid')[0];
        }

        return selectedColumns[0].fieldConfig.fieldName;
      }

      function getValueFieldName(
        cols: { fieldConfig: CsvUploadModalFieldInfo; value: string }[]
      ): CsvUploadModalFieldInfo['fieldName'] {
        const fieldNames = cols.map((col) => col.fieldConfig.fieldName);
        if (fieldNames.length > 1 && fieldNames.includes('uuid')) {
          // Use uuid as the value field.
          return fieldNames.filter((name) => name === 'uuid')[0];
        }

        // Otherwise use the first field's name as the value field.
        return selectedColumns[0].fieldConfig.fieldName;
      }

      // Remove duplicates, and transform string[] into Option[].
      const newData = Array.from(
        new Set(
          [
            ...value,
            ...data
              .filter((item) => !!item)
              .map((item) => {
                // NOTE: label and value for the chip UI are grabbed from the first column of the csv.
                //  This may not be ideal behavior in cases where we're uploading multiple columns.
                //  If we start importing multiple columns, we'll need a smarter or more explicit way to handle this.
                const returnVal: Option = {
                  label: capitalize(item[getLabelFieldName(selectedColumns)]),
                  value: item[getValueFieldName(selectedColumns)],
                };

                // Since `value` only contains a single value, we attach a key / value object to meta for all required columns.
                returnVal.meta = item;

                return returnVal;
              }),
          ]
            // Strip out any options that don't have a value attribute.
            .filter((item) => !!item.value)
        )
      ).sort();

      onValueChanged(newData);

      setShowConfirmUpload(false);
      setOpenModal(false);
    }
  }, [csvData, selectedColumns, file, value, onValueChanged]);

  /**
   * Change event for the <Autocomplete> component. Triggered only when a new item is selected from the dropdown.
   */
  const handleChange = useCallback(
    (_: SyntheticEvent<Element, Event>, newValues: unknown[]) => {
      autoCompleteRef.current?.scrollTo(
        0,
        autoCompleteRef.current?.scrollHeight
      );
      const transformedNewValues = newValues as Option[];
      const selectedValues = transformedNewValues.map((item) => item.value);

      if (
        selectedValues.includes('Anyone') &&
        !value.map((item) => item.value).includes('Anyone')
      ) {
        const filterTitle = label.split(' ')[0];

        setInputValue('');
        return onValueChanged([
          {
            label: `Anyone (All ${filterTitle.toLowerCase()} owners)`,
            value: 'Anyone',
          },
        ]);
      }

      const newValue = unique
        ? transformedNewValues.length > 0
          ? [
              {
                label: capitalize(
                  transformedNewValues[transformedNewValues.length - 1]?.label
                ),
                value: capitalize(
                  transformedNewValues[transformedNewValues.length - 1]?.value
                ),
                meta: transformedNewValues[transformedNewValues.length - 1]
                  ?.meta,
              },
            ]
          : []
        : Array.from(
            new Set(
              transformedNewValues
                .map((item) => ({
                  label: capitalize(item.label),
                  value: capitalize(item.value),
                  meta: item.meta,
                }))
                .sort()
            )
          );

      onValueChanged(newValue.filter((item) => item.value !== 'Anyone'));
      setInputValue('');
    },
    [autoCompleteRef, value, label, onValueChanged, unique]
  );

  /**
   * Combines the options array passed in with fetchedOptions.
   *
   * @returns {Option[]} - The options to be displayed in the autocomplete.
   */
  const mapOptions = () => {
    if (fetch) {
      return fetchedOptions;
    }

    if (
      disabledCustomValues ||
      options.map((item) => item.label).includes(inputValue)
    ) {
      return options;
    }

    const returnVal = [...options];
    if (inputValue) {
      returnVal.push({
        label: inputValue,
        value: inputValue,
      });
    }

    return returnVal;
  };

  return {
    openModal,
    setOpenModal,
    mainLabel,
    showConfirmUpload,
    csvData,
    formatedLabel,
    file,
    loadingFetch,
    inputValue,
    mapOptions,
    fetchedOptions,
    handleChange,
    fetchAutocompleteDebounced,
    handleFile,
    handleSubmit,
    setShowConfirmUpload,
    setSelectedColumns,
    selectedColumns,
    setInputValue,
  };
}
